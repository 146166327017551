<template>
  <div id="modalRegistrationRecommendCompany" class="modal">
    <div class="modal-background"></div>
    <!-- Modal content -->
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Daten anfordern</h2>
          <span class="close" @click="close">&times;</span>
        </div>
        <div class="modal-body">
          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 2">
            <font-awesome-icon icon="check" class="icon color-success text-3xl" />
            <p><span class="font-bold">Anfrage erfolgreich versendet!</span><br>Wir informieren Dich per E-Mail, sobald die Daten bereit stehen.<br>Kontaktiere uns gerne, falls Du Fragen hast.</p>
          </div>

          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 1">
            <font-awesome-icon icon="spinner" class="icon" pulse />
            <p></p>
          </div>

          <div class="text-center flex flex-col gap-2" v-show="status == 0">
            <p>Du kannst einen Bericht Deiner Nutzerdaten für den StadtbahnfahrerClub anfordern. Die Generierung kann bis zu 2 Wochen dauern. Wir informieren Dich per E-Mail, sobald die Daten bereit stehen.</p>
            <button
              @click="sendAction()"
              class="button-filled text-center btn-fullsize w-full"
            >
              Daten anfordern
            </button>
          </div>

          <div class="text-center text-gray-600 font-sans">
            <p><small>ID: {{user.id}}</small></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalSettingAccountPasswordChange",
  data() {
    return {
      show: this.showModal,
      stepCompleteDisabled: true,
      status: 0,
    };
  },
  props: {
    showModal: false,
  },
  mounted() {
    // this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  methods: {
    sendAction() {
      this.status = 1

      this.$store.dispatch("requestPrivateData").then((a) => {
        if (a.status == true) {
          this.status = 2
        }
      });
    },
    close() {
      // this.status = 0
      this.$emit("close");
    },
  },
};
</script>




