<template>
  <div id="Account">
    <div class="card-header bg-brand px-3 py-4 rounded-t-lg">
      <p>
        <span class="brand-color-dark">Privatsphäre</span><br />
        Wir respektieren Deine Privatsphäre. Erlaube uns die Erfassung Deines Nutzungsverhaltens, damit wir unseren Service weiter verbessern können.
      </p>
    </div>
    <div class="card-content">

      <div class="grid grid-cols-2 gap-6">
        <div class="col-span-full">
          <label for="accounttyp" class="block text-sm font-bold">Erfassung Deines Nutzungsverhaltens</label>
          <iframe class="col-span-full h-72 sm:h-48"
            style="border: 0; width: 100%"
            src="https://analytics.yellowcat.dev/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=ffffff&fontSize=&fontFamily="
            ></iframe>
        </div>

        <hr class="col-span-full">
        
        <div class="col-span-full">
          <label for="accounttyp" class="block text-sm font-bold">Do-not-Track</label>
          <p>Unsere Website beachtet die "Do-not-Track"-Einstellung Deines Browsers.</p>
        </div>

        <hr class="col-span-full">

        <div class="col-span-full">
          <label for="accounttyp" class="block text-sm font-bold">Personenbezogene Daten</label>
          <p>Für unseren Service erheben und verarbeiten wir personenbezogene Daten.</p>
          
          <p class="my-4" v-show="requestDataDownloadUrl">
            Der Bericht liegt als ZIP-Archiv zum Download bereit:<br><br> 
            <a class="card-button" :href="requestDataDownloadUrl">Bericht herunterladen</a>
          </p>
          <button class="card-button" @click="showModalSettingAccountRequestData" v-show="!requestDataDownloadUrl">Daten anfordern</button>
        </div>
        <ModalSettingAccountRequestData
                    v-show="isModalSettingAccountRequestDataVisible"
                    @close="closeModal"
                  />
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import ModalSettingAccountRequestData from "@/components/ModalSettingAccountRequestData";

export default {
  name: "Account",
  components: {
    ModalSettingAccountRequestData
  },
  data() {
    return {
      isModalSettingAccountRequestDataVisible: false,
      requestDataDownloadUrl: false
    };
  },
  methods: {
    showModalSettingAccountRequestData() {
      this.isModalSettingAccountRequestDataVisible = true;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.isModalSettingAccountRequestDataVisible = false;
      document.body.classList.remove("modal-open");
    },
  },
  mounted() {
    this.$store.dispatch("fetchPrivateData").then((a) => {
      if (a.status) {
        this.requestDataDownloadUrl = a.result
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
};
</script>